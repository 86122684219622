export const filterOptions = Object.freeze([
  {
    id: 0,
    name: 'Eigen school',
    key: 'ownSchool'
  },
  {
    id: 1,
    name: 'Gemeente',
    key: 'ownMunicipality'
  },
  {
    id: 2,
    name: 'Provincie',
    key: 'ownProvince'
  },
  {
    id: 3,
    name: 'Alle scholen',
    key: 'allSchools'
  }
]);