export const rankingDummy = {
  user: {
    id: 1,
    rankNumber: 2,
    name: 'De bo',
    score: '14.323',
  },
  topTen: [
      {
          id: 125,
          name: "Class teacher!",
          score: 3182,
          rankNumber: 1
      },
      {
          id: 165,
          name: "Test one",
          score: 2389,
          rankNumber: 2
      },
      {
          id: 173,
          name: "Ferri and Ed Testing",
          score: 495,
          rankNumber: 3
      },
      {
          id: 169,
          name: "Class no teacher!aaaa",
          score: 426,
          rankNumber: 4
      },
      {
          id: 159,
          name: "Fake Class33",
          score: 70,
          rankNumber: 5
      },
      {
          id: 133,
          name: "One mored",
          score: 50,
          rankNumber: 6
      },
      {
          id: 140,
          name: "Fake Class222",
          score: 0,
          rankNumber: 7
      },
      {
          id: 141,
          name: "This is a new class",
          score: 0,
          rankNumber: 8
      },
      {
          id: 143,
          name: "new class 24",
          score: 0,
          rankNumber: 9
      },
  ]
}
