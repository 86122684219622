<template>
  <div v-if="userRank" class="rankings content-container">
    <PageTitle :title="activeWeek ? $t('SCORE') : $t('RANK_LIST')" class="page-title"/>
    <div class="title-background">
      <h1 class="page-section-title">{{ selectedClass.name }}</h1>
      <DropdownSingle :name="$t('OVERVIEW.SELECT_CLASS')" :options="myClasses" :onselect="setSelectedClass"
                      :selected-element="selectedClass"/>
    </div>

    <!-- Ranking Achieved -->
    <h1 class="page-section-title page-subtitle">
      {{ activeWeek ? $t('RANKINGS.CURRENT_RANK') : $t('RANKINGS.FINAL_RANK') }}
    </h1>
    <RankBanner :rank="currentRank" :bits-amount="userRank.score"/>

    <i18n-t v-if="!activeWeek" keypath="RANKINGS.ACHIEVED_DESCRIPTION" tag="p" class="page-section-text achieved-text">
      <template #rank>
        <b style="font-weight: 500">{{ currentRank.name }}</b>
      </template>
      <template #amountBits>
        <b style="font-weight: 500">{{ userRank.score }} {{ $t('BITS') }}</b>
      </template>
    </i18n-t>

    <i18n-t v-else keypath="RANKINGS.DURING_DESCRIPTION"  tag="p" class="page-section-text achieved-text">
      <template #place>
        <b style="font-weight: 500">{{ userRank.rank }}e</b>
      </template>
    </i18n-t>

    <!-- Final Standings -->
    <div v-if="activeWeek || passedWeek">
      <h1 class="page-section-title page-subtitle">
        {{ $t(weekTitleText) }}
      </h1>

      <div class="rankings-final-text">
        <p v-if="activeWeek" class="page-section-text">
          <i18n-t keypath="RANKINGS.ACHIEVED_RANK">
            <template #place>
              <b style="font-weight: 500">{{ userRank.rank }}e</b>
            </template>
          </i18n-t>
        </p>
        
        <div v-if="passedWeek">
          <p class="page-section-text">
            <i18n-t :keypath="passedWeekText">
              <template #place>
                <b style="font-weight: 500">{{ userRank.rank }}e</b>
              </template>
            </i18n-t>
          </p>
        </div>

        <DropdownSingle
          v-if="passedWeek"
          :name="selectedFilter.name"
          :options="filterOptions" 
          :onselect="setSelectedFilter"
          :selected-element="selectedFilter"
          :icon="rankingIcon"
          class="filter-dropdown"
        />
      </div>
      <a href="https://mediamasters.nl/prijzen/" target="_blank" class="link-main">{{ $t('RANKINGS.VIEW_PRIZES') }}</a>
    </div>
    <RankingList :rankings="topRanks" :user-ranking="userRank" class="rankings"/>
  </div>
</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'
import RankingList from '@/components/elements/ranking/RankingList'
import {rankingDummy} from '@/utils/data/rankingDummy'
import {computed, ref} from '@vue/reactivity'
import RankBanner from '../../../components/partials/overview/RankBanner'
import {GET_SCHOOL_CLASSES, GET_USER_RANKS} from '@/store/modules/teacherPortal/actions'
import {useStore} from 'vuex'
import {SET_SELECTED_CLASS} from '@/store/modules/teacherPortal/mutations'
import {useRoute, useRouter} from 'vue-router'
import {watch} from '@vue/runtime-core'
import DropdownSingle from '@/components/elements/missions/DropdownSingle'
import {ROUTE_NAMES_TEACHER_PORTAL} from '../../../router/modules/teacherPortal'
import {classRanks} from '@/utils/data/classRanks'
import {onBeforeUnmount} from 'vue'
import {PLAY_WITHOUT_CLASS} from '@/views/teacherPortal/overview/Overview'
import {ACTIVE_WEEK_STATUS, PASSED_WEEK_STATUS} from '@/utils/data/weekStatus'
import rankingIcon from '@/assets/icons/icn_ranking.svg'
import { filterOptions } from '@/utils/data/rankDropdownFilter'

export default {
  name: 'Ranking',
  components: {
    RankBanner,
    RankingList,
    PageTitle,
    DropdownSingle
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const selectedClass = computed(() => store.getters.getSelectedClass)
    const classLevel = computed(() => selectedClass.value ? selectedClass.value.classType.gameDifficultyId : null)
    const showAllSchools = ref(false)

    const globalWeekData = computed(() => store.getters.getWeekData)
    const activeWeek = computed(() => globalWeekData.value?.status === ACTIVE_WEEK_STATUS)
    const passedWeek = computed(() => globalWeekData.value?.status === PASSED_WEEK_STATUS)

    const profile = computed(() => store.getters.getTeachersProfile)
    const classes = computed(() => store.getters.getSchoolClasses)
    const myClasses = computed(() => classes.value ? classes.value.filter(aClass => aClass.teachers.some(teacher => teacher.id === profile.value.teacherId)) : [])

    const selectedFilter = ref(filterOptions[0])

    const passedWeekText = computed(() => {
      switch (selectedFilter.value.key) {
        case 'ownSchool':
          return 'RANKINGS.ACHIEVED_FINAL_RANK';
        case 'ownMunicipality':
          return 'RANKINGS.ACHIEVED_FINAL_RANK_MUNICIPALITY';
        case 'ownProvince':
          return 'RANKINGS.ACHIEVED_FINAL_RANK_PROVINCE';
        case 'allSchools':
          return 'RANKINGS.ACHIEVED_FINAL_RANK_ALL_SCHOOLS';
      }
    })

    const weekTitleText = computed(() => {
      if (activeWeek.value) {
        if (profile.value.isSpecialEducationSchool) {
          if (classLevel.value === 1) {
            return 'RANKINGS.TITLE_RANKING_SPECIAL_LOW'
          } else {
            return 'RANKINGS.TITLE_RANKING_SPECIAL_HIGH'
          }
        } else {
          if (classLevel.value === 1) {
            return 'RANKINGS.TITLE_RANKING_NORMAL_LOW'
          } else {
            return 'RANKINGS.TITLE_RANKING_NORMAL_HIGH'
          }
        }
      } else if (passedWeek.value) {
        if (profile.value.isSpecialEducationSchool) {
          if (classLevel.value === 1) {
            return 'RANKINGS.TITLE_RANKING_FINAL_SPECIAL_LOW'
          } else {
            return 'RANKINGS.TITLE_RANKING_FINAL_SPECIAL_HIGH'
          }
        } else {
          if (classLevel.value === 1) {
            return 'RANKINGS.TITLE_RANKING_FINAL_NORMAL_LOW'
          } else {
            return 'RANKINGS.TITLE_RANKING_FINAL_NORMAL_HIGH'
          }
        }
      }
    })

    function setSelectedFilter(filter) {
      selectedFilter.value = filter
      getRankingData(selectedClass.value.id)
    }

    const ranks = classRanks
    const rankData = computed(() => store.getters.getUserRanks)
    const topRanks = computed(() => rankData.value ? rankData.value.classesRanked : [])
    const userRank = computed(() => rankData.value ? rankData.value.schoolClassToRank : null)

    if (selectedClass.value) {
      getRankingData(selectedClass.value.id)
    }

    watch(() => selectedClass.value, (curr, prev) => {
      if (curr) {
        getRankingData(curr.id)
        console.log(selectedClass.value)
      }
    })

    watch(() => activeWeek.value, (curr, prev) => {
      getRankingData(selectedClass.value.id);
    })

    watch(rankData, () => {
      if (rankData && !rankData.value.schoolClassToRank && classes.value.length) {
        store.commit(SET_SELECTED_CLASS, myClasses.value[0])
      }
    })

    onBeforeUnmount(() => {
      // Reset back to no class selected
      if (rankData && !rankData.value?.schoolClassToRank) {
        store.commit(SET_SELECTED_CLASS, PLAY_WITHOUT_CLASS)
      }
    })

    function getRankingData(classId) {
      store.dispatch(GET_USER_RANKS, {classId, filterBy: selectedFilter.value.key }).then(response => {
        if (response.error) {
          router.push({name: ROUTE_NAMES_TEACHER_PORTAL.OVERVIEW})
        }
      })
    }

    const classId = route.params.classId ? Number.parseInt(route.params.classId) : null
    store.dispatch(GET_SCHOOL_CLASSES, {}).then(() => {
      if (classId) {
        const classData = myClasses.value.find((classData) => classData.id === classId) ?? myClasses[0]
        setSelectedClass(classData)
      } else if (!selectedClass.value) {
        setSelectedClass(myClasses.value[0])
      }
    })

    const currentRank = computed(() => {
      if (!userRank.value) {
        return null
      } else {
        const rank = ranks.find(x => x.id === userRank.value.rankCategory)

        return rank
      }
    })

    function setSelectedClass(classData) {
      store.commit(SET_SELECTED_CLASS, classData)
    }

    return {
      activeWeek,
      passedWeek,
      rankingDummy,
      rankData,
      profile,
      //pagination
      topRanks,
      userRank,
      ranks,
      selectedClass,
      classLevel,
      setSelectedClass,
      currentRank,
      myClasses,
      showAllSchools,
      filterOptions,
      selectedFilter,
      setSelectedFilter,
      rankingIcon,
      weekTitleText,
      passedWeekText,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.rankings {
  max-width: 1400px;

  .title-background {
    margin-bottom: rem(25);
    margin-top: rem(11);
    background-color: rgb(255, 255, 255);
    border-radius: rem(20);
    padding: rem(30);
    display: flex;
    justify-content: space-between;
    gap: rem(16);
    align-items: center;

    h1 {
      margin-bottom: 0;
    }
  }

  .achieved-text {
    margin-bottom: rem(40);
    margin-top: rem(20);
  }

  .page-subtitle {
    margin-bottom: rem(16);
  }

  .rankings {
    margin-top: rem(24);
  }

  .link-main {
    color: #0E4E95;
    cursor: pointer;
  }
}

.rankings-final-text {
  display: flex;
  justify-content: space-between;

  .page-section-text {
    max-width: rem(452);
  }
}

.button-white {
  background-color: #FFF;
  color: #0E4E95;
  font-size: rem(16);
  font-weight: 700;
  border-radius: rem(5);
  border: none;
  padding: rem(10) rem(20);
  cursor: pointer;
}

.button-arrow {
  margin-left: rem(16);
}

.filter-dropdown {
  background: #fff;
  height: fit-content;
  width: rem(220);
  border-radius: rem(8);

  :deep(.choice-selector) {
    padding: rem(12) rem(16);
    justify-content: space-between;
  }

  :deep(.choiceSelector) {
    font-weight: 400;
    color: var(--blue_dark);
  }

  :deep(.dropdown-content) {
    left: 0;
    top: rem(55);
    width: rem(220);
  }
}
</style>
