<template>
  <div>
    <div class="ranking-wrapper">
      <div
        v-for="ranking in rankings"
        :key="ranking.id"
        class="ranking rank-item"
        :class="[{'sticky': ranking.id === userRanking.id && isExpandable && listExpanded}]"
        v-show="isExpandable ? !listExpanded && ranking.rank < 4 || listExpanded : true"
      >

        <RankingEntry
          :rank="ranking.rank"
          :name="ranking.className"
          :school="ranking.schoolName"
          :score="ranking.score"
          :is-user="ranking.id === userRanking.id"
          class="rank-item"
        />
      </div>
    </div>
    <div v-if="isExpandable && !listExpanded" class="expand-button" @click="expandList">
      <div
        v-for="(circle, index) in 3"
        :key="index"
        class="expand-dot"
      />
    </div>

    <div v-if="isExpandable && !listExpanded && userRanking.rank > 3" class="ranking-wrapper">
      <div class="ranking rank-item">
        <RankingEntry
          :rank-number="userRanking.rank"
          :name="userRanking.className"
          :school="userRanking.schoolName"
          :score="userRanking.score"
          :is-user="true"
          class="rank-item"
        />
      </div>
      <div v-if="rankings[userRanking.rank]" class="ranking rank-item">
        <RankingEntry
          :rank-number="rankings[userRanking.rank].rank"
          :name="rankings[userRanking.rank].className"
          :school="rankings[userRanking.rank].schoolName"
          :score="rankings[userRanking.rank].score"
          class="rank-item"
        />
      </div>
    </div>

    <RankingEntry
      v-if="!rankings.some(ranking => ranking.id === userRanking.id)"
      :rank-number="userRanking.rank"
      :name="userRanking.name"
      :school="userRanking.schoolName"
      :score="userRanking.score"
      :is-user="true"
      class="rank-item"
    />
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import RankingEntry from '@/components/elements/ranking/RankingEntry'

export default {
  name: 'RankingList',
  components: {
    RankingEntry
  },
  props: {
    // TODO: Pass the API data.
    rankings: {
      type: Array,
      required: true
    },
    userRanking: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const isExpandable = computed(() => props.rankings.length > 5)

    const listExpanded = ref(false)

    function expandList() {
      listExpanded.value = true
    }

    return {
      isExpandable,
      listExpanded,
      expandList
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.ranking-wrapper {
  margin-bottom: rem(8);
  display: flex;
  flex-direction: column;
}

.closed {
  height: rem(244);
  overflow: hidden;
}

.expanded {
  height: fit-content;
}

.rank-item {
  &:not(:last-child) {
    margin-bottom: rem(8);
  }
}
.sticky {
  position: sticky;
  top: rem(8);
  bottom: rem(8);
}

.expand-button {
  background-color: white;
  border-radius: rem(20);
  display: flex;
  justify-content: center;
  padding: rem(16);
  cursor: pointer;
  margin-bottom: rem(8);

  .expand-dot {
    height: rem(10);
    width: rem(10);
    background-color: #373B52;
    border-radius: 50%;

    &:not(:last-child) {
      margin-right: rem(8);
    }
  }
}
</style>
